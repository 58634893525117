<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-4"
        >
          <b-img class="login-image" fluid :src="imgUrl" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <router-view></router-view>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue';

import LoginForm from '@/views/login/LoginForm';
import ResetForm from '@/views/login/ResetForm';
import { BRow, BCol, BImg, BLink } from 'bootstrap-vue';
import axios from '@/libs/axios';

import { getUserData } from '@/auth/utils';

export default {
  components: {
    LoginForm,
    ResetForm,
    BRow,
    BCol,
    BImg,
    BLink,
    VuexyLogo,
  },
  mounted() {
    axios.get('ping');
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login/login.svg'),
    };
  },
  computed: {
    imgUrl() {
      return this.sideImg;
    },
    shouldResetPassword() {
      const userData = getUserData();
      return userData && !userData.isPasswordSet;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
